body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* invisible scrollbar */

/* .sidebar::-webkit-scrollbar {
  width: 5px !important
} */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #909294 #212529;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #212529;
}

*::-webkit-scrollbar-track:hover {
  background-color: #212529;
}

*::-webkit-scrollbar-track:active {
  background-color: #212529;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #90929431;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #90929431;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #90929431;
}
.invert-colors {
  filter: invert(1);
}

.tag {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.dropdown-menu-dark .dropdown-item:hover {
  background-color: #4033f2;
  color: white;
}

.viewport-grid {
  width: 100%;
  height: 100%;
  display: grid;
  background-color: #212529;
  gap: 1px;
}

.viewport {
  outline: 1px solid #90929431;
}

.viewport-active {
  outline: 1px solid #4033f2;
  z-index: 1;
}

.spin4 {
  height: 200px;
  width: 200px;
  border: 3px solid #4033f2;
  margin: 0 auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader2 {
  border: 3px solid #e0edff;
  width: 220px;
  height: 220px;
  position: relative;
  top: -216px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader3 {
  border: 3px solid #94b6e5;
  width: 240px;
  height: 240px;
  position: relative;
  top: -452px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3px solid #4033f2;
  width: 260px;
  height: 260px;
  position: relative;
  top: -708px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

#text {
  position: relative;
  top: -857px;
  font-weight: bold;
}

.grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-gap: 1px; /* Gap between grid items */
}

.grid-full {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
}

.first-column {
  grid-row: span 2; /* Takes up 2 rows */
}

/* responsive layout */
.appContainer {
  display: flex;
  height: 100svh;
  flex-direction: column;
}
.sidebar {
  /* Common styles for all screen sizes */
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;
}
.toolNavbarContainer {
  flex-direction: column;
  overflow-x: auto;
  text-align: center;
}
.touchNone{
touch-action: none;
user-select: none;
-webkit-user-drag: none;
}

.responsiveFontSize{
  font-size: 11px;
}

/* Small devices (portrait phones) */
@media only screen and (max-width: 767px) {
  .sidebar {
    gap: 2rem;
  }
  .thumb {
    width: 125px;
    height: 90px;
  }
  .thumbTitle {
    font-size: 10px;
  }
  .responsiveFontSize{
    font-size: 9px;
  }  
}

/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px)  {
  .sidebar {
    /* Stack elements vertically for tablet portrait */
    gap: 2rem;
  }
  .thumb {
    width: 125px;
    height: 90px;
  }
  .thumbTitle {
    font-size: 10px;
  }
  .responsiveFontSize{
    font-size: 9px;
  }  
}

/* Large devices (laptops/desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    /* Add your styles for horizontal tablet here */
    display: block;
    height: 100vh;
  }
  .appContainer {
    flex-direction: column;
  }
  .toolNavbarContainer {
    display: block;
  }
  .thumb {
    width: 250px;
    height: 180px;
  }
  .height-lg-100 {
    height: 100%;
   }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  .sidebar {
    /* Add your styles for horizontal desktop here */
    display: block;
    height: 100vh;
  }
  .appContainer {
    flex-direction: column;
  }
  .toolNavbarContainer {
    display: block;
  }
  .thumb {
    width: 250px;
    height: 180px;
  }

  .height-lg-100 {
  height: 100%;
  }
}

.selectable {
  -webkit-user-select:text;
  -moz-user-select:text;
  -ms-user-select:text;
  user-select:text;
}

.toolVisibility{
  display: none !important;
}

@media only screen and (min-width: 768px) and (pointer: fine) and (hover: hover) {
  .toolVisibility{
    display: flex !important;
  }
}

@media only screen and (min-width: 992px) {
  .toolVisibility{
    display: flex !important;
  }
}
